<template>
    <div class="image-aspect-ratio">
        <div :style="{paddingTop: `${aspectRatio * 100}%`}" />

        <img
            :src="src"
            :width="width"
            :height="height"
            :alt="alt"
            :class="imageClass"
            v-bind="$attrs"
            v-on="$listeners"
        >
    </div>
</template>

<script>
/**
 * Image container that:
 * - Maintains the aspect ratio of the asset.
 * - Provides an initial height so the content does not "jump" when the asset loads.
 */
export default {
    name: 'ImageAspectRatio',
    props: {
        src: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },
        alt: {
            type: String,
            required: false,
            default: undefined,
        },
        imageClass: {
            type: [String, Array, Object],
            required: false,
            default: undefined,
        },
    },
    computed: {
        aspectRatio() {
            return this.height / this.width;
        },
    },
};
</script>

<style lang="scss">
.image-aspect-ratio {
    position: relative;

    > img {
        max-width: 100%;
        max-height: 100%;
        @include position-absolute-center(true, true);
    }
}
</style>
