<template>
    <b-row class="w-100 m-0">
        <b-col
            lg="6"
            class="d-flex flex-column justify-content-center align-items-center"
        >
            <div
                style="width: 300px;"
                class="max-w-100 pb-5 pb-lg-0"
            >
                <image-aspect-ratio
                    class="mb-5"
                    image-class="user-select-none pointer-events-none"
                    :src="illustration.src"
                    :width="illustration.width"
                    :height="illustration.height"
                />

                <h5 class="text-center mb-3 font-weight-bold">
                    {{ title }}
                </h5>

                <div class="text-center small text-muted">
                    {{ subtitle }}
                </div>
            </div>
        </b-col>

        <b-col
            lg="6"
            class="d-flex flex-column justify-content-center align-items-center"
        >
            <div style="width: 350px; max-width: 100%;">
                <slot />
            </div>
        </b-col>
    </b-row>
</template>

<script>
import ImageAspectRatio from '@/components/common/ImageAspectRatio';
import Illustration from '@/assets/images/auth/coaching-illustration.svg';

export default {
    name: 'AuthDefault',
    components: {ImageAspectRatio},
    props: {
        illustration: {
            type: Object,
            required: false,
            default: () => ({
                src: Illustration,
                // 252.96 222.58
                width: 253,
                height: 223,
            }),
        },
        title: {
            type: String,
            required: false,
            default: function() {
                return this.$t('auth.messages.welcome.title');
            },
        },
        subtitle: {
            type: String,
            required: false,
            default: function() {
                return this.$t('auth.messages.welcome.subtitle');
            },
        },
    },
};
</script>
