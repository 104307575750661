import {mapActions} from 'vuex';
import RedirectsAuthenticated from '@/components/auth/mixins/RedirectsAuthenticated';

export default {
    mixins: [RedirectsAuthenticated],
    methods: {
        ...mapActions('auth', [
            'login',
        ]),
        authenticate(data, origin) {
            if (!data.user.canLoginWeb) {
                throw new Error(this.$t('auth.messages.userCantLoginWeb'));
            }

            this.login(data);

            this.redirectAuthenticated(origin);
        },
    },
};
