<template>
    <auth-default>
        <b-card>
            <b-card-title
                class="mb-4 font-weight-bold"
                title-tag="h4"
            >
                {{ $t('auth.register.title') }}
            </b-card-title>

            <!-- Show general error message. -->
            <p
                v-if="errorMessage"
                class="small text-danger mb-3"
            >
                {{ errorMessage }}
            </p>

            <b-form @submit.prevent="register()">
                <b-form-group
                    :invalid-feedback="form.errors.name"
                    :label="$t('inputs.name.label')"
                    :state="!form.errors.name"
                >
                    <b-input v-model="form.name" />
                </b-form-group>


                <b-form-group
                    :invalid-feedback="_.get(form.errors, 'invitationData.companyName')"
                    :label="$t('users.model.companyName.label')"
                    :state="!_.get(form.errors, 'invitationData.companyName')"
                >
                    <b-input v-model="form.invitationData.companyName" />
                </b-form-group>

                <b-form-group
                    :invalid-feedback="form.errors.email"
                    :label="$t('inputs.email.label')"
                    :state="!form.errors.email"
                >
                    <!-- The email should come from route query and is uneditable. -->
                    <b-input
                        v-model="form.email"
                        readonly
                        :placeholder="$t('auth.register.messages.emailShouldBePrefilled')"
                        type="email"
                    />
                </b-form-group>

                <b-form-group
                    :invalid-feedback="form.errors.phone"
                    :label="$t('users.model.phone.label') + $t('inputs.optional')"
                    :state="!form.errors.phone"
                >
                    <b-input v-model="form.phone" />
                </b-form-group>

                <b-form-group
                    :invalid-feedback="form.errors.password"
                    :label="$t('inputs.password.label')"
                    :state="!form.errors.password"
                >
                    <b-input
                        v-model="form.password"
                        type="password"
                    />
                </b-form-group>

                <btn-resource
                    block
                    class="mt-5"
                    :resource="form"
                    :disabled="!dataIsValid || !form.changed()"
                    type="submit"
                    variant="primary"
                >
                    {{ $t('auth.register.actions.register') }}
                </btn-resource>
            </b-form>
        </b-card>
    </auth-default>
</template>

<script>
import AuthDefault from '@/components/auth/Default';
import Model from '@/models/vue-mc/Model';
import Authenticates from '@/components/auth/mixins/Authenticates';

export default {
    name: 'Register',
    components: {AuthDefault},
    mixins: [Authenticates],
    data() {
        return {
            form: new Model(
                {
                    name: '',
                    email: this.$route.query.email,
                    password: '',
                    phone: '',
                    invitationId: this.$route.query.invitation_id,
                    invitationToken: this.$route.query.invitation_token,
                    // For now this register form is specificly for coach.
                    invitationData: {
                        companyName: '',
                    },
                },
                null,
                {endpoint: 'auth/register'},
            ),
        };
    },
    computed: {
        dataIsValid() {
            return !!this.form.invitationId
                && !!this.form.invitationToken
                && !!this.form.email;
        },
        errorMessage() {
            if (this.dataIsValid) {
                return this.form.errors.invitationId
                    || this.form.errors.invitationToken
                    || this.form.errors.message;
            }

            return this.$t('auth.register.messages.invalidRegisterLink');
        },
    },
    methods: {
        async register() {
            // Do not allow if registration data is invalid.
            if (!this.dataIsValid) return;

            await this.form.save();

            const data = {
                user: this.form.user,
                apiToken: this.form.apiToken,
            };

            this.authenticate(data, 'register');
        },
    },
};
</script>
